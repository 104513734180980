import { Layout, Meta, Section } from "~/ui";
import { Routes } from "~/models";

import aboutImg from "~/assets/images/about.png";

export default function About(): JSX.Element {
  return (
    <Layout>
      <Section
        bgColor="creamGradient"
        baseBgColorTop="primary"
        roundedCorners={{ "top-left": true }}
      >
        <div className="row">
          <div className="col-12 col-lg-7 mb-4 mb-lg-0">
            <div>
              <h1 className="d-flex flex-column text-primary">
                About
                <span className="display-1">YVolunteer</span>
              </h1>
              <p>
                This Y "youth" website is for young people and how you can get
                involved in volunteering. Volunteering can be a pathway to
                getting a job and can help you gain skills and experience in an
                area you are interested in. Volunteering can also be social and
                improve personal development in areas like gaining confidence
                and building mental health.
              </p>
              <p className="mb-4">
                You can find an inspirational volunteer opportunity that makes a
                difference to you and your community by watching some short
                videos on this website, getting ready to volunteer by hearing
                other people's stories and finding out about what volunteering
                opportunities are open to you. Finally you will be able to take
                the next steps by creating a volunteer profile or using the
                resources to apply for a role and then go on your volunteer
                journey.
              </p>
              <div className="text-center">
                <div
                  className="d-inline-block strokes stroke-2"
                  style={{
                    padding: "10%",
                    margin: "-10%",
                  }}
                >
                  <mark>Have a great time!</mark>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-5 text-center mb-4 mb-lg-0">
            <img
              src={aboutImg}
              alt="Volunteers packing boxes and a volunteer painting a fence"
              className="img-fluid mt-lg-n5"
              width={364}
              height={735}
            />
          </div>
        </div>
      </Section>
    </Layout>
  );
}

export const Head = () => {
  return <Meta title="About" url={Routes.About} />;
};
